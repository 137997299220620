// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-ait-api-ait-md": () => import("./../docs/AIT_API/ait.md" /* webpackChunkName: "component---docs-ait-api-ait-md" */),
  "component---docs-http-rest-api-app-push-md": () => import("./../docs/HTTP_REST_API/AppPush.md" /* webpackChunkName: "component---docs-http-rest-api-app-push-md" */),
  "component---docs-http-rest-api-call-md": () => import("./../docs/HTTP_REST_API/Call.md" /* webpackChunkName: "component---docs-http-rest-api-call-md" */),
  "component---docs-http-rest-api-chat-md": () => import("./../docs/HTTP_REST_API/Chat.md" /* webpackChunkName: "component---docs-http-rest-api-chat-md" */),
  "component---docs-http-rest-api-cloud-storrage-md": () => import("./../docs/HTTP_REST_API/CloudStorrage.md" /* webpackChunkName: "component---docs-http-rest-api-cloud-storrage-md" */),
  "component---docs-http-rest-api-introduction-md": () => import("./../docs/HTTP_REST_API/Introduction.md" /* webpackChunkName: "component---docs-http-rest-api-introduction-md" */),
  "component---docs-http-rest-api-qr-code-solutions-md": () => import("./../docs/HTTP_REST_API/QrCodeSolutions.md" /* webpackChunkName: "component---docs-http-rest-api-qr-code-solutions-md" */),
  "component---docs-http-rest-api-send-message-md": () => import("./../docs/HTTP_REST_API/SendMessage.md" /* webpackChunkName: "component---docs-http-rest-api-send-message-md" */),
  "component---docs-http-rest-api-short-link-creation-md": () => import("./../docs/HTTP_REST_API/ShortLinkCreation.md" /* webpackChunkName: "component---docs-http-rest-api-short-link-creation-md" */),
  "component---docs-http-rest-api-sms-md": () => import("./../docs/HTTP_REST_API/SMS.md" /* webpackChunkName: "component---docs-http-rest-api-sms-md" */),
  "component---docs-http-rest-api-tts-md": () => import("./../docs/HTTP_REST_API/Tts.md" /* webpackChunkName: "component---docs-http-rest-api-tts-md" */),
  "component---docs-http-rest-api-video-conference-md": () => import("./../docs/HTTP_REST_API/VideoConference.md" /* webpackChunkName: "component---docs-http-rest-api-video-conference-md" */),
  "component---docs-introduction-introduction-md": () => import("./../docs/Introduction/Introduction.md" /* webpackChunkName: "component---docs-introduction-introduction-md" */),
  "component---docs-lookup-api-hlr-lookup-md": () => import("./../docs/Lookup_Api/HlrLookup.md" /* webpackChunkName: "component---docs-lookup-api-hlr-lookup-md" */),
  "component---docs-lookup-api-introduction-md": () => import("./../docs/Lookup_Api/Introduction.md" /* webpackChunkName: "component---docs-lookup-api-introduction-md" */),
  "component---docs-lookup-api-mnp-lookup-md": () => import("./../docs/Lookup_Api/MnpLookup.md" /* webpackChunkName: "component---docs-lookup-api-mnp-lookup-md" */),
  "component---docs-mail-api-introduction-md": () => import("./../docs/MailApi/Introduction.md" /* webpackChunkName: "component---docs-mail-api-introduction-md" */),
  "component---docs-mail-api-send-md": () => import("./../docs/MailApi/Send.md" /* webpackChunkName: "component---docs-mail-api-send-md" */),
  "component---docs-smpp-api-introduction-md": () => import("./../docs/SmppApi/introduction.md" /* webpackChunkName: "component---docs-smpp-api-introduction-md" */),
  "component---docs-smpp-api-send-receive-md": () => import("./../docs/SmppApi/SendReceive.md" /* webpackChunkName: "component---docs-smpp-api-send-receive-md" */),
  "component---docs-status-smsc-md": () => import("./../docs/Status/Smsc.md" /* webpackChunkName: "component---docs-status-smsc-md" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-not-good-looking-js": () => import("./../src/pages/index_notGoodLooking.js" /* webpackChunkName: "component---src-pages-index-not-good-looking-js" */)
}

